import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#E43282",
      hover: "#cb1a6a",
    },
    common: {
      white: "#f9f9f9",
      black: "#303031",
      activeColor: "#7B5AB5",
    },
  },
  typography: {
    h1: {
      fontFamily: "BadTyp",
      wordBreak: "break-word",
      fontSize: "3rem", // Large base size for desktop
      fontWeight: 700,
      lineHeight: 1.2,
      "@media (max-width:960px)": {
        // md breakpoint
        fontSize: "2.5rem",
      },
      "@media (max-width:600px)": {
        // sm breakpoint
        fontSize: "2rem",
      },
    },
    h2: {
      fontFamily: "BadTyp",
      wordBreak: "break-word",
      fontSize: "2.5rem", // Large base size for desktop
      fontWeight: 600,
      lineHeight: 1.3,
      "@media (max-width:960px)": {
        // md breakpoint
        fontSize: "2rem",
      },
      "@media (max-width:600px)": {
        // sm breakpoint
        fontSize: "1.75rem",
      },
    },
    h3: {
      fontFamily: "BadTyp",
      wordBreak: "break-word",
      fontSize: "2rem", // Default font size for h3
      fontWeight: 500,
      lineHeight: 1.4,
      "@media (max-width:960px)": {
        // md breakpoint
        fontSize: "1.75rem",
      },
      "@media (max-width:600px)": {
        // sm breakpoint
        fontSize: "1.5rem",
      },
    },
    h4: {
      fontFamily: "BadTyp",
      wordBreak: "break-word",
      fontSize: "1.75rem", // Default font size for h4
      fontWeight: 500,
      lineHeight: 1.5,
      "@media (max-width:960px)": {
        // md breakpoint
        fontSize: "1.5rem",
      },
      "@media (max-width:600px)": {
        // sm breakpoint
        fontSize: "1.25rem",
      },
    },
    p: {
      fontFamily: "Calibri",
      fontSize: "1rem", // Default font size for paragraphs
      "@media (max-width:960px)": {
        // md breakpoint
        fontSize: "0.9rem",
      },
      "@media (max-width:600px)": {
        // sm breakpoint
        fontSize: "0.8rem",
      },
    },
  },
  body: {
    margin: 0,
  },
  // Add more customizations here
})

export default theme
